import React from "react";
import styled from "styled-components";

const AboutContainer = styled.section`
  padding: 4rem 2rem;
  background-color: #212693;
  color: #fff;
`;

const AboutContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const AboutTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #e71d2e;
`;

const AboutText = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
`;

const AboutSection: React.FC = () => (
  <AboutContainer>
    <AboutContent>
      <AboutTitle>About Kamala Harris</AboutTitle>
      <AboutText>
        Kamala Harris has dedicated her life to public service, fighting for the
        rights of all Americans. From her early days as a prosecutor to her
        current role as Vice President, she has consistently championed justice,
        equality, and opportunity. Kamala’s leadership is driven by a deep
        commitment to making a difference in the lives of the people she serves.
      </AboutText>
      <AboutText>
        As the daughter of immigrants, Kamala understands the importance of
        diversity and the value that every person brings to the American story.
        Her campaign for the presidency is rooted in the belief that our nation
        can be a more just and equal society, where everyone has the opportunity
        to thrive.
      </AboutText>
    </AboutContent>
  </AboutContainer>
);

export default AboutSection;
