import { Theme } from "typed-component-gallery";

export const themeConfig: Theme = {
  colors: [
    "hsla(237, 63%, 35%,1)",
    "hsla(355, 81%, 51%, 1)",
    "hsla(222, 81%, 46%, 1)",
  ],

  white: "hsla(90, 60%, 96%, 1)",
  font: "Gothic A1",
  noHeaderText: true,

  fontSize: "1.25px",
  borderRadius: "5px",
  border: "1px solid black",
  mode: "light",
  heading: {
    color: "hsla(216, 96%, 10%,1)",
  },

  input: {
    border: true,
    rounded: true,
    extLabel: true,
  },
};
