import React from "react";
import styled from "styled-components";

const PoliciesContainer = styled.section`
  padding: 4rem 2rem;
  background-color: #f4f4f4;
  color: #212693;
`;

const PoliciesTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #e71d2e;
`;

const PolicyItem = styled.div`
  margin-bottom: 3rem;
`;

const PolicyTitle = styled.h3`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #212693;
`;

const PolicyText = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  color: #333;
`;

const PoliciesSection: React.FC = () => (
  <PoliciesContainer>
    <PoliciesTitle>Kamala Harris’s Key Policies</PoliciesTitle>
    <PolicyItem>
      <PolicyTitle>Healthcare for All</PolicyTitle>
      <PolicyText>
        Kamala Harris is committed to providing universal healthcare coverage,
        ensuring that every American has access to affordable healthcare. Her
        plan focuses on expanding Medicare, reducing prescription drug costs,
        and increasing accessibility to medical services in underserved
        communities.
      </PolicyText>
    </PolicyItem>
    <PolicyItem>
      <PolicyTitle>Criminal Justice Reform</PolicyTitle>
      <PolicyText>
        Harris is a strong advocate for reforming the criminal justice system to
        ensure fairness and equality for all citizens. She supports ending mass
        incarceration, abolishing private prisons, and implementing
        community-based alternatives to traditional incarceration.
      </PolicyText>
    </PolicyItem>
    <PolicyItem>
      <PolicyTitle>Climate Change Action</PolicyTitle>
      <PolicyText>
        Fighting climate change is a top priority. Kamala Harris supports
        aggressive measures to reduce carbon emissions and promote clean energy.
        Her plan includes rejoining the Paris Agreement, investing in renewable
        energy, and creating green jobs to boost the economy while protecting
        the planet.
      </PolicyText>
    </PolicyItem>
    <PolicyItem>
      <PolicyTitle>Economic Equality</PolicyTitle>
      <PolicyText>
        Harris’s policies aim to reduce income inequality and ensure economic
        opportunities for all Americans, particularly marginalized communities.
        She supports raising the minimum wage, expanding access to education,
        and providing tax relief to middle and low-income families.
      </PolicyText>
    </PolicyItem>
  </PoliciesContainer>
);

export default PoliciesSection;
