import { SiteConfig } from "typed-component-gallery";
import logo from "../assets/harrislogo.svg";

import Dash from "../components/pages/dash";
import { Hotmala } from "../components/pages/hotmala";

export const site: SiteConfig = {
  id: "whaleSeason",
  name: "Whale Season",
  logo: logo,
  inverseLogo: logo,
  api: "http://localhost:5001",
  defaultMode: "user",
  headerTrans: true,
  peopleConfig: [],
  sideWidget: [],
  noAuth: true,
  noHeader: true,

  pages: [
    {
      name: "/",
      component: <Dash />,
    },
    {
      name: "hotmala",
      hidden: true,
      component: <Hotmala />,
    },
  ],
  eloiseConfig: {
    endPoint: "http://127.0.0.1:5001/eloiselife-c5cf6/us-central1/musicGpt",
    chatLog: "vibez/chat",
    initMessage:
      "Hey there, Describe the music you are seeking and I will see what I can do. ",
  },
  hostingSite: "vibez",
};
