import styled from "styled-components";

const Section = styled.section`
  margin: 2rem;
  padding: 2rem;
  background-color: #f4f4f4;
`;

const TitleWrap = styled.div`
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #212693;
`;

const CopyWrap = styled.div`
  color: #333;
  font-size: 1rem;
  line-height: 1.8;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 2rem;

  @media (max-width: 450px) {
    max-width: 100%;
  }
`;

const StrongText = styled.strong`
  font-size: 22px;
  line-height: 160%;

  @media (max-width: 450px) {
    font-size: 18px;
    line-height: 160%;
  }
`;

const ColumnWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

const Column = styled.div`
  flex-basis: 33.33%";
  padding: 0 1rem;

  @media (max-width: 768px) {
    flex-basis: 100%;
    padding: 0;
  }
`;
const ColumnWide = styled.div`
  flex-basis: 66.66%;
  padding: 0 1rem;

  @media (max-width: 768px) {
    flex-basis: 100%;
    padding: 0;
  }
`;

const Quote = styled.blockquote`
  margin: 2rem 0;
  font-size: 1.5rem;
  font-style: italic;
  color: #e71d2e;
`;

const NewsBodyWrap = () => (
  <Section>
    <TitleWrap>
      <Title>Meet Vice President Kamala Harris</Title>
    </TitleWrap>
    <CopyWrap>
      <Image
        decoding="async"
        src="https://kamalaharris.com/wp-content/uploads/2024/07/DSC00101_150dpi.jpg"
        alt="Kamala Harris"
        style={{ width: "839px", height: "auto" }}
      />
      <p>
        <StrongText>
          Vice President Kamala Harris has devoted her career to fighting for
          the people.
        </StrongText>
      </p>
      <ColumnWrap>
        <ColumnWide>
          <p>
            As the daughter of parents who brought her to civil rights marches
            in a stroller, she was inspired to tackle injustice from an early
            age. She took that mission to county courtrooms, the California
            Attorney General’s office, the United States Senate, and the White
            House.
          </p>
          <p>
            Now she is running for President of the United States to continue
            protecting our freedoms, delivering justice, and expanding
            opportunity so that every American can not just get by, but get
            ahead.
          </p>
        </ColumnWide>
        <Column>
          <Image
            decoding="async"
            src="https://kamalaharris.com/wp-content/uploads/2024/07/345899602_824914539233712_6506234950726652837_n.jpg"
            alt="Kamala Harris"
          />
        </Column>
      </ColumnWrap>
      <p>
        As vice president, she’s been a trusted partner to President Joe Biden
        in their work to take on the powerful and make change for the people —
        from standing up against extremists to defending reproductive freedom to
        taking on Big Pharma to bring down prescription drug costs and cap the
        price of insulin at $35 a month for our seniors.
      </p>
      <p>
        The Biden-Harris administration has achieved a historic record of
        accomplishment, including: bringing our economy back from the brink of
        disaster to create nearly 16 million new jobs; investing over $1
        trillion in infrastructure projects like repairing roads and bridges,
        removing every lead pipe in America, improving public transit, and
        expanding access to high-speed internet; strengthening the Affordable
        Care Act and lowering health insurance premiums to save millions of
        Americans an average of $800 per year; expanding health care for
        veterans exposed to toxins; enacting the first meaningful gun safety
        reform in decades and bringing violent crime down to a near 50-year low;
        passing the largest-ever investment in tackling the climate crisis; and
        appointing the first Black woman to the United States Supreme Court.
      </p>
      <p>
        Vice President Harris has proudly represented the United States on the
        global stage in meetings with over 150 world leaders and bolstered vital
        alliances against tyranny abroad — and she is just as committed to
        stopping would-be authoritarians and dictators at home. She is leading
        the charge to protect fundamental freedoms, including the right to an
        abortion and the right to vote.
      </p>
      <Image
        decoding="async"
        src="https://kamalaharris.com/wp-content/uploads/2024/07/20230425_DNC_VPOTUS_NARAL-Howard-Washington-DC_MK4_8585_150dpi.jpg"
        alt="Kamala Harris"
      />
      <p>
        In the U.S. Senate, she led on legislation to raise wages and bring down
        costs for American families — on housing, health care, child care,
        college, and more. Serving on the Senate Judiciary Committee, Homeland
        Security and Governmental Affairs Committee, Select Committee On
        Intelligence, and Committee On Budget, she fought for critical causes
        like criminal justice reform, climate action, infrastructure
        investments, and election security. She also grilled Donald Trump’s
        nominees, including Justice Brett Kavanaugh, and Cabinet officials,
        including then-Attorney General Jeff Sessions, to get answers for the
        American people.
      </p>
      <p>
        As California’s attorney general, she went up against the big banks and
        won — securing $20 billion for middle class homeowners who were facing
        foreclosure during the Great Recession. She went up against predatory
        for-profit colleges and won — delivering a $1.1 billion settlement for
        students and veterans who got scammed. She went up against
        discrimination and won — leading the team that helped bring down
        California’s Proposition 8 at the U.S. Supreme Court and fighting for
        marriage equality nationwide. She prosecuted transnational gangs that
        exploited women and children and trafficked in guns, drugs, and human
        beings, and fought to require for-profit insurers to cover contraception
        and other reproductive health services.
      </p>
      <p>
        Beginning her career in the Alameda County District Attorney’s Office,
        she specialized in prosecuting child sexual assault cases. She later
        joined the San Francisco District Attorney’s office, and went on to win
        election to two terms as district attorney — where she started a
        program, “Back on Track,” to give first-time drug offenders the
        opportunity to earn high school diplomas and get jobs, that became a
        national model for decreasing recidivism rates.
      </p>
      <p>
        Vice President Harris is a proud graduate of Howard University and the
        University of California, Hastings College of the Law.
      </p>
      <ColumnWrap>
        <Column>
          <Image
            decoding="async"
            src="https://kamalaharris.com/wp-content/uploads/2024/07/50911775813_272f7629c7_o_150dpi.jpg"
            alt="Kamala Harris"
          />
        </Column>
        <ColumnWide>
          <p>
            In 2013, her best friend set her up on a blind date with Douglas
            Emhoff. The next year, she married him. Their large blended family
            includes their children, Ella and Cole, who call her Momala.
          </p>
          <p>
            Throughout her life, she’s broken barriers, and she’s now the first
            woman, first Black American, and first South Asian American to serve
            as vice president. She always remembers and acts on the words of her
            mother, Dr. Shyamala Gopalan Harris, a renowned breast cancer
            researcher:
          </p>
        </ColumnWide>
      </ColumnWrap>
      <Quote>
        <p>
          Kamala, you may be the first to do many things, but make sure you are
          not the last.
        </p>
      </Quote>
      <p>
        <StrongText>
          On January 20, 2025, if we all do our part, she will once again make
          history — and she will make sure the doors of opportunity are wide
          open for all to follow.
        </StrongText>
      </p>
      <Image
        decoding="async"
        src="https://kamalaharris.com/wp-content/uploads/2024/07/ed274b83bf8d5ea8e086232987dd300a.jpeg?w=1024"
        alt="Kamala Harris"
      />
    </CopyWrap>
  </Section>
);

export default NewsBodyWrap;
