import React from "react";
import styled from "styled-components";
import { handleOutboundClick } from "./heroSection";

const FooterContainer = styled.footer`
  padding: 2rem 1rem;
  background: #222;
  color: #fff;
  text-align: center;
`;

const FooterText = styled.p`
  margin-bottom: 1rem;
`;

const SocialLinks = styled.div`
  a {
    margin: 0 1rem;
    color: #007bff;
    font-size: 1.2rem;

    &:hover {
      color: #fff;
    }
  }
`;

const Footer: React.FC = () => (
  <FooterContainer>
    <FooterText>
      Join the Movement. Support Kamala Harris for President in 2024.
    </FooterText>
    <SocialLinks>
      <a
        href="https://www.cornelwest2024.com/"
        onClick={handleOutboundClick}
        target="_blank"
        rel="noopener noreferrer">
        Twitter
      </a>
      <a
        href="https://www.cornelwest2024.com/"
        target="_blank"
        onClick={handleOutboundClick}
        rel="noopener noreferrer">
        Facebook
      </a>
      <a
        href="https://www.cornelwest2024.com/"
        target="_blank"
        onClick={handleOutboundClick}
        rel="noopener noreferrer">
        Instagram
      </a>
    </SocialLinks>
  </FooterContainer>
);

export default Footer;
