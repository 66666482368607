import React from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { useEloise } from "typed-component-gallery";
import AboutSection from "../aboutSection";
import GlobalStyle from "../assets/globalStyles";
import "../assets/index.css";
import Footer from "../footer";
import HeroSection from "../heroSection";
import NewsBodyWrap from "../moreInfo";
import PoliciesSection from "../PoliciesSection";
const Dash: React.FC<{}> = () => {
  const { theme, logic } = useEloise();
  const location = useLocation();

  // Parse the search query string
  const queryParams = new URLSearchParams(location.search);

  const navigate = useNavigate();

  return (
    <>
      <Container
        fluid
        style={{ width: "100%" }}
        className="mx-0 p-0">
        <GlobalStyle />
        <HeroSection />
        <PoliciesSection />
        <AboutSection />
        <NewsBodyWrap />
        <Footer />
      </Container>
    </>
  );
};

export default Dash;
