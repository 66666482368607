import { Col, Container, Row } from "react-bootstrap";
import hotmala from "../../assets/hotmala.webp";

export const Hotmala = () => {
  return (
    <Container fluid>
      <Row>
        <Col
          lg={10}
          className="mx-auto">
          <img src={hotmala}></img>
        </Col>
      </Row>
    </Container>
  );
};
