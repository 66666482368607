import React from "react";
import styled from "styled-components";
import kamalaImage from "./assets/kamala.webp";

const HeroContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  background: linear-gradient(
      to bottom,
      rgba(33, 38, 147, 0.8),
      rgba(231, 29, 46, 0.8)
    ),
    url(${kamalaImage}) no-repeat center center/cover;
  color: #fff;
  text-align: center;
`;

const HeroContent = styled.div`
  max-width: 80%;
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: white;
`;

const HeroSubtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: white;
`;

const HeroButton = styled.a`
  padding: 0.75rem 2rem;
  background: #fff;
  color: #e71d2e;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;

  &:hover {
    background: #f4f4f4;
  }
`;
export const handleOutboundClick = () => {
  window.gtag("event", "click", {
    event_category: "outbound",
    event_label: "External Site",
    transport_type: "beacon",
    event_callback: function () {
      document.location = "https://www.cornelwest2024.com/";
    },
  });
};
const HeroSection: React.FC = () => (
  <HeroContainer>
    <HeroContent>
      <HeroTitle>Kamala Harris for President 2024</HeroTitle>
      <HeroSubtitle>Join the Movement for a Better Future</HeroSubtitle>
      <HeroButton
        href="https://www.cornelwest2024.com/"
        onClick={handleOutboundClick}>
        Get Involved
      </HeroButton>
    </HeroContent>
  </HeroContainer>
);

export default HeroSection;
